<template>
  <div class="advantage-box flex justify-between">
    <div class="item">
      <div class="img-box">
        <img src="~assets/img/ys01.png" alt="" />
      </div>
      <h6>10年积累沉淀</h6>
      <p>
        覆盖多种业务场景，重视每一个客户，业务经验丰富，与上百家机构的精诚合作
      </p>
    </div>

    <div class="item">
      <div class="img-box">
        <img src="~assets/img/ys04.png" alt="" />
      </div>
      <h6>强大的业务团队</h6>
      <p>
        300名经验丰富的客户经理，全面的服务 高效、专业、便捷 解决您的需求
      </p>
    </div>

    <div class="item">
      <div class="img-box">
        <img src="~assets/img/ys03.png" alt="" />
      </div>
      <h6>10多个地区的业务覆盖</h6>
      <p>
        深圳、东莞、中山、惠州、西安、重庆、武汉、长沙、郑州、南宁、佛山、广州
      </p>
    </div>

    <div class="item">
      <div class="img-box">
        <img src="~assets/img/ys02.png" alt="" />
      </div>
      <h6>专业</h6>
      <p>
        我们愿以专业、优质、高效的金融服务 做我国创新金融的引领者
        和社会责任的担当者
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advantage"
};
</script>

<style lang="scss" scoped>
.advantage-box {
  .item {
    text-align: center;
    width: 25%;
    padding: 20px 50px;
    .img-box {
      width: 50%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    h6 {
      margin: 15px auto;
      font-size: 20px;
      font-weight: bold;
      color: #222;
    }
    p {
      font-size: 16px;
      line-height: 1.6;
    }
  }
}
</style>
