<template>
  <div class="content-title">
    <h6>{{ contentObj.title }}</h6>
    <div class="txt">
      <p v-for="item in contentObj.txt" :key="item">
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentTitle",
  props: {
    contentObj: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.content-title {
  text-align: center;
  h6 {
    font-size: 42px;
    color: #222;
    font-weight: 500;
  }
  .txt {
    margin-top: 10px;
    font-size: 18px;
    color: #777;
  }
}
</style>
