<template>
  <div class="nvironment-box flex justify-between flex-wrap">
    <div class="flex flex-column align-center item">
      <img src="~assets/img/hj01.jpg" alt="" class="bxb-tran" />
      <h6>走廊</h6>
    </div>
    <div class="flex flex-column align-center item">
      <img src="~assets/img/hj02.jpg" alt="" class="bxb-tran" />
      <h6>会议室</h6>
    </div>
    <div class="flex flex-column align-center item">
      <img src="~assets/img/hj03.jpg" alt="" class="bxb-tran" />
      <h6>工作区</h6>
    </div>
    <div class="flex flex-column align-center item">
      <img src="~assets/img/hj04.jpg" alt="" class="bxb-tran" />
      <h6>休息区</h6>
    </div>
    <div class="flex flex-column align-center item">
      <img src="~assets/img/hj05.jpg" alt="" class="bxb-tran" />
      <h6>工作区2</h6>
    </div>
    <div class="flex flex-column align-center item">
      <img src="~assets/img/hj06.jpg" alt="" class="bxb-tran" />
      <h6>会议室</h6>
    </div>
    <div class="flex flex-column align-center item">
      <img src="~assets/img/hj07.jpg" alt="" class="bxb-tran" />
      <h6>餐厅</h6>
    </div>
    <div class="flex flex-column align-center item">
      <img src="~assets/img/hj08.jpg" alt="" class="bxb-tran" />
      <h6>会议室</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nvironment"
};
</script>

<style lang="scss" scoped>
.nvironment-box {
  .item {
    width: 25%;
    padding: 30px 50px;
    img {
      width: 100%;
      display: block;
      border-radius: 50%;
      border: 5px solid rgba(0, 0, 0, 0.05);
    }
    h6 {
      font-size: 22px;
      padding: 30px 0 0;
    }
    &:hover img {
      box-shadow: 5px 10px 12px rgba(0, 0, 0, 0.2);
    }
    &:hover h6 {
      color: #597ef7;
    }
  }
}
</style>
