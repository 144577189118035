<template>
  <div class="page-box page-home">
    <ban-view :banStyle="banStyle"></ban-view>

    <div class="content-box bxb-box bxb-p70">
      <content-title :contentObj="advantageTitle"></content-title>

      <div class="bxb-con bxb-p50-top">
        <advantage></advantage>
      </div>
    </div>

    <div class="content-box bxb-box bxb-p70 bg-white">
      <content-title :contentObj="nvironmentTitle"></content-title>
      <div class="bxb-con bxb-p50-top">
        <nvironment></nvironment>
      </div>
    </div>

    <div class="content-box bxb-box bxb-p70">
      <content-title :contentObj="aboutTitle"></content-title>
      <div class="bxb-con bxb-p50-top">
        <about-content></about-content>
      </div>
    </div>

    <div class="content-box bxb-box bxb-p70 bg-white">
      <content-title :contentObj="newsTitle"></content-title>
      <div class="bxb-con bxb-p50-top">
        <news-list :newsList="newsList"></news-list>
      </div>
    </div>
  </div>
</template>

<script>
import BanView from "components/content/banView/BanView";
import ContentTitle from "components/content/contentTitle/ContentTitle";
import NewsList from "components/content/newsList/NewsList";
import Advantage from "./childComponents/Advantage";
import Nvironment from "./childComponents/Nvironment";
import AboutContent from "./childComponents/AboutContent";
export default {
  name: "Home",
  components: {
    BanView,
    ContentTitle,
    Advantage,
    Nvironment,
    AboutContent,
    NewsList
  },
  data() {
    return {
      banStyle: {
        height: "600px",
        backgroundImage: 'url("https://ylxd.xcongzb.com/img/ban.jpg")'
      },
      advantageTitle: {
        title: "全国10个地区的业务覆盖",
        txt: [
          "300名经验丰富的客户经理 与上百家机构的精诚合作",
          "以及未来无限发展的可能性"
        ]
      },
      nvironmentTitle: {
        title: "工作环境",
        txt: ["开放的沟通渠道，可以很好的表达自己的思路想法"]
      },
      aboutTitle: {
        title: "关于我们",
        txt: ["300名经验丰富的客户经理 与上百家机构的精诚合作"]
      },
      newsTitle: {
        title: "新闻中心",
        txt: []
      },
      newsList: [
        {
          id: 0,
          title: "同心协力  合作共赢",
          text:
            "2020年12月31日百色市远兰小额贷款有限责任公司与中国人寿财产保险有限公司正式签约“提放保”业务合作，国寿财中心领导与我公司融资部总监郭总共同签署了该合作协议书，同时标志着我公司与中国人寿财产合作正式进入实施阶段",
          img: "https://ylxd.xcongzb.com/img/news01.jpg"
        },
        {
          id: 1,
          title: "金钟徒步，园林野炊，我们一起走进温柔的秋天",
          text:
            "落叶知秋，微风袭来,秋天褪去了夏日的燥热，带着一丝凉意,天朗气清的今天,我们徒步金钟水库，到山庄园林野炊",
          img: "https://ylxd.xcongzb.com/img/news02.jpg"
        },
        {
          id: 2,
          title: "感谢有你，携手同行—深圳分公司员工生日会",
          text:
            "小时候，生日是一家人的欢乐；有父母、有蛋糕、有蜡烛；上学时，生日是同学们的联欢；有同窗情谊、有举杯欢畅；工作后，生日是同事们的欢聚；有筑梦未来、有携手共进。",
          img: "https://ylxd.xcongzb.com/img/news03.jpg"
        }
      ]
    };
  }
};
</script>

<style lang="sass" scoped></style>
